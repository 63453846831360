import { default as _91_46_46_46cms_93wXsQB0lHYEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_932iXkUVMtG6Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexwAHfCtIJZeMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93AOiTnD1LfjMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/akcii/[...slug].vue?macro=true";
import { default as index4Cgrb2vew5Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93GOrtseHeGMMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/articles/[...slug].vue?macro=true";
import { default as indexnhcswNcj4uMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/articles/index.vue?macro=true";
import { default as indexQdkce6mlFpMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/authorization/index.vue?macro=true";
import { default as indexqfH0zvPAB5Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/cart/index.vue?macro=true";
import { default as indexFCFbRGjEj4Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93uN6M8yr2ZVMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalog/[...slug].vue?macro=true";
import { default as indexclFHeeMj1cMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93OY44K6NJmEMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultXKfqTilWR2Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalogsearch/result.vue?macro=true";
import { default as _91_46_46_46slug_93dWa1NjE0rOMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_933yIuacdITnMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/fabriki/[...slug].vue?macro=true";
import { default as indexPwkHwKobyLMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93PBQkYNaGgOMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/product/[...slug].vue?macro=true";
import { default as indexxzbFnsd336Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/profile/index.vue?macro=true";
import { default as indexq80VskohqOMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/profile/orders/index.vue?macro=true";
import { default as indexDFp4hpfQgOMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93Szfbm0nRjCMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/sets/[...slug].vue?macro=true";
import { default as indexH24HK3oy5UMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93wXsQB0lHYEMeta?.name ?? "cms",
    path: _91_46_46_46cms_93wXsQB0lHYEMeta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93wXsQB0lHYEMeta || {},
    alias: _91_46_46_46cms_93wXsQB0lHYEMeta?.alias || [],
    redirect: _91_46_46_46cms_93wXsQB0lHYEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_932iXkUVMtG6Meta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_932iXkUVMtG6Meta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_932iXkUVMtG6Meta || {},
    alias: _91_46_46_46slug_932iXkUVMtG6Meta?.alias || [],
    redirect: _91_46_46_46slug_932iXkUVMtG6Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexwAHfCtIJZeMeta?.name ?? "3d-projects",
    path: indexwAHfCtIJZeMeta?.path ?? "/3d-projects",
    meta: indexwAHfCtIJZeMeta || {},
    alias: indexwAHfCtIJZeMeta?.alias || [],
    redirect: indexwAHfCtIJZeMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93AOiTnD1LfjMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93AOiTnD1LfjMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93AOiTnD1LfjMeta || {},
    alias: _91_46_46_46slug_93AOiTnD1LfjMeta?.alias || [],
    redirect: _91_46_46_46slug_93AOiTnD1LfjMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index4Cgrb2vew5Meta?.name ?? "akcii",
    path: index4Cgrb2vew5Meta?.path ?? "/akcii",
    meta: index4Cgrb2vew5Meta || {},
    alias: index4Cgrb2vew5Meta?.alias || [],
    redirect: index4Cgrb2vew5Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93GOrtseHeGMMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93GOrtseHeGMMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93GOrtseHeGMMeta || {},
    alias: _91_46_46_46slug_93GOrtseHeGMMeta?.alias || [],
    redirect: _91_46_46_46slug_93GOrtseHeGMMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexnhcswNcj4uMeta?.name ?? "articles",
    path: indexnhcswNcj4uMeta?.path ?? "/articles",
    meta: indexnhcswNcj4uMeta || {},
    alias: indexnhcswNcj4uMeta?.alias || [],
    redirect: indexnhcswNcj4uMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexQdkce6mlFpMeta?.name ?? "authorization",
    path: indexQdkce6mlFpMeta?.path ?? "/authorization",
    meta: indexQdkce6mlFpMeta || {},
    alias: indexQdkce6mlFpMeta?.alias || [],
    redirect: indexQdkce6mlFpMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexqfH0zvPAB5Meta?.name ?? "cart",
    path: indexqfH0zvPAB5Meta?.path ?? "/cart",
    meta: indexqfH0zvPAB5Meta || {},
    alias: indexqfH0zvPAB5Meta?.alias || [],
    redirect: indexqfH0zvPAB5Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexFCFbRGjEj4Meta?.name ?? "cart-success",
    path: indexFCFbRGjEj4Meta?.path ?? "/cart/success",
    meta: indexFCFbRGjEj4Meta || {},
    alias: indexFCFbRGjEj4Meta?.alias || [],
    redirect: indexFCFbRGjEj4Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93uN6M8yr2ZVMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93uN6M8yr2ZVMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93uN6M8yr2ZVMeta || {},
    alias: _91_46_46_46slug_93uN6M8yr2ZVMeta?.alias || [],
    redirect: _91_46_46_46slug_93uN6M8yr2ZVMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexclFHeeMj1cMeta?.name ?? "catalog",
    path: indexclFHeeMj1cMeta?.path ?? "/catalog",
    meta: indexclFHeeMj1cMeta || {},
    alias: indexclFHeeMj1cMeta?.alias || [],
    redirect: indexclFHeeMj1cMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93OY44K6NJmEMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93OY44K6NJmEMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93OY44K6NJmEMeta || {},
    alias: _91_46_46_46slug_93OY44K6NJmEMeta?.alias || [],
    redirect: _91_46_46_46slug_93OY44K6NJmEMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultXKfqTilWR2Meta?.name ?? "catalogsearch-result",
    path: resultXKfqTilWR2Meta?.path ?? "/catalogsearch/result",
    meta: resultXKfqTilWR2Meta || {},
    alias: resultXKfqTilWR2Meta?.alias || [],
    redirect: resultXKfqTilWR2Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93dWa1NjE0rOMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93dWa1NjE0rOMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93dWa1NjE0rOMeta || {},
    alias: _91_46_46_46slug_93dWa1NjE0rOMeta?.alias || [],
    redirect: _91_46_46_46slug_93dWa1NjE0rOMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_933yIuacdITnMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_933yIuacdITnMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_933yIuacdITnMeta || {},
    alias: _91_46_46_46slug_933yIuacdITnMeta?.alias || [],
    redirect: _91_46_46_46slug_933yIuacdITnMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexPwkHwKobyLMeta?.name ?? "fabriki",
    path: indexPwkHwKobyLMeta?.path ?? "/fabriki",
    meta: indexPwkHwKobyLMeta || {},
    alias: indexPwkHwKobyLMeta?.alias || [],
    redirect: indexPwkHwKobyLMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93PBQkYNaGgOMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93PBQkYNaGgOMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93PBQkYNaGgOMeta || {},
    alias: _91_46_46_46slug_93PBQkYNaGgOMeta?.alias || [],
    redirect: _91_46_46_46slug_93PBQkYNaGgOMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexxzbFnsd336Meta?.name ?? "profile",
    path: indexxzbFnsd336Meta?.path ?? "/profile",
    meta: indexxzbFnsd336Meta || {},
    alias: indexxzbFnsd336Meta?.alias || [],
    redirect: indexxzbFnsd336Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexq80VskohqOMeta?.name ?? "profile-orders",
    path: indexq80VskohqOMeta?.path ?? "/profile/orders",
    meta: indexq80VskohqOMeta || {},
    alias: indexq80VskohqOMeta?.alias || [],
    redirect: indexq80VskohqOMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexDFp4hpfQgOMeta?.name ?? "recently",
    path: indexDFp4hpfQgOMeta?.path ?? "/recently",
    meta: indexDFp4hpfQgOMeta || {},
    alias: indexDFp4hpfQgOMeta?.alias || [],
    redirect: indexDFp4hpfQgOMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Szfbm0nRjCMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93Szfbm0nRjCMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93Szfbm0nRjCMeta || {},
    alias: _91_46_46_46slug_93Szfbm0nRjCMeta?.alias || [],
    redirect: _91_46_46_46slug_93Szfbm0nRjCMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexH24HK3oy5UMeta?.name ?? "wishlist",
    path: indexH24HK3oy5UMeta?.path ?? "/wishlist",
    meta: indexH24HK3oy5UMeta || {},
    alias: indexH24HK3oy5UMeta?.alias || [],
    redirect: indexH24HK3oy5UMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/pages/wishlist/index.vue").then(m => m.default || m)
  }
]