import routerOptions0 from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240827121723/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}